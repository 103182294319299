.home{
    background-color: #F4DFC8;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    background: linear-gradient(-45deg, #fdfbfb, #ebedee, #f4dfc8, #e8e8e8);
    background-size: 400% 400%;
    animation: gradientBg 15s ease infinite;
}

@keyframes gradientBg {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  

.linkDiv{
    border: 3px solid black;
    border-radius: 8px;
    margin-right: 85%;
    padding: 1%;
    display: flex;
    flex-direction: column;
    
}

.linkedIn{
    /* margin-top: 5%;
    margin-left: 5%;
    margin-bottom: 5%; */
    margin-bottom: 10%;
    width: 25px;
    transition: transform 0.3s;
    z-index: 2;
}

.linkedIn:hover{
    cursor: pointer;
    transform: scale(1.12);
}

.gitHub{
    width: 26px;
    padding-top: 100%;
   transition: transform 0.3s;
   z-index: 3;
}

.emailIcon{
    width: 26px;
    padding-top: 100%;
    transition: transform 0.3s;
}

.emailIcon:hover{
    cursor: pointer;
    transform: scale(1.12);
}

.gitHub:hover{
    cursor: pointer;
    transform: scale(1.12);
}

.homeContainer{
    display: flex;
    justify-content: center;
    /* background-color: white; */
    align-items: center;
    width: 100%;
    height: 1%;
    flex: 1;
    flex-direction: column;
    margin-top: -12%;
}

.linksAndIntro{
    display: flex;
    justify-content: space-between;
    /* width: 100%;
    margin-top: 5%; */
}
.corner-box {
    position: absolute;
    width: 70%;
    height: 50%;
    border-color: black;    
    border-radius: 8px;
    /* margin-top: 4%; */

    pointer-events: none;
}

.corner-box::before,
.corner-box::after {
  content: '';
  position: absolute;
  background: black; /* Background color for the lines */
}

.corner-box-top-left::before {
    top: 0;
    left: 0;
    width: 8%; /* Length of the horizontal line */
    height: 6px; /* Thickness of the line */
}

.corner-box-top-left::after {
    top: 0;
    left: 0;
    width: 6px; /* Thickness of the line */
    height: 15%; /* Length of the vertical line */
}

.corner-box-bottom-right::before {
    right: 0;
    bottom: 0;
    width: 7%; /* Length of the horizontal line */
    height: 6px; /* Thickness of the line */
}
  
  .corner-box-bottom-right::after {
    right: 0;
    bottom: 0;
    width: 6px; /* Thickness of the line */
    height: 16%; /* Length of the vertical line */
}

.mainContent{
    margin-top: -10%;
    position: relative;
    display: flex;
    gap: 10%;
    /* background-color: beige; */
    
}

@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translatey(20px);
    }
    to {
      opacity: 1;
      transform: translatey(0);
    }
  }

.homeIntro{
    animation: fadeIn 1.5s ease-out forwards;
}

.introName{
    color: #E3651D;
    text-decoration: underline;
    font-size: larger;
}

.homeIntro h2{
    text-align: left;
}
.homeIntro p{
    width: 50%;
    text-align: left;
}

.spanStrong{
    font-weight: bold;

}
@keyframes float {
    0%, 100% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-10px);
    }
}

.homeImage{
    animation: float 6s ease-in-out infinite;
}



.introButtons{
    margin-top: 6%;
    display: flex;
    gap: 5%;
    position: relative;
    z-index: 2;
}

.introButton{
    color: white;
    border: none;
    padding: 1% 2% 1% 2%;
    border-radius: 5px;
    font-weight: 600;
    z-index: 2;
    /* z-index: -9999990; */
    transition: transform 0.3s; 
}

.home .introButtons .introButton:hover {
    cursor: pointer;
    transform: scale(1.12);
}
  
.projectsButton{
    background-color: #191919;
}


.experienceButton{
    background-color: #E3651D;
}

/* responsiveness */

@media (max-width: 1100px) {
    .mainContent{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -15%;
        /* background-color: #E3651D; */
        /* width: 35%; */
    }

    .homeIntro{
        /* background-color: aliceblue; */
        /* flex: 1 1 0; */
        /* display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; */
        width: 50%;
    }
    .homeImage{
        /* background-color: #e8e8e8; */
        margin-top: 10%;
        width: 50%;
    }
    .homeImage img{
        /* margin-top: 20%; */
    }

    .corner-box {
        position: absolute;
        width: 50%;
        height: 60%;
        border-color: black;    
        border-radius: 8px;
        /* margin-top: 4%; */
    }
    
    .corner-box::before,
    .corner-box::after {
      content: '';
      position: absolute;
      background: black; /* Background color for the lines */
    }
    
    .corner-box-top-left::before {
        top: 0;
        left: 0;
        width: 8%; /* Length of the horizontal line */
        height: 6px; /* Thickness of the line */
    }
    
    .corner-box-top-left::after {
        top: 0;
        left: 0;
        width: 6px; /* Thickness of the line */
        height: 15%; /* Length of the vertical line */
    }
    
    .corner-box-bottom-right::before {
        right: 0;
        bottom: 0;
        width: 7%; /* Length of the horizontal line */
        height: 6px; /* Thickness of the line */
    }
      
      .corner-box-bottom-right::after {
        right: 0;
        bottom: 0;
        width: 6px; /* Thickness of the line */
        height: 16%; /* Length of the vertical line */
    }
    
}

@media (max-width: 670px) {
   
    .mainContent{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -40%;
        /* background-color: #E3651D; */
        /* width: 35%; */
        z-index: 0;
    }

    .homeIntro{
        /* background-color: aliceblue; */
        /* flex: 1 1 0; */
        /* display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; */
        width: 50%;
    }

    .homeIntro h2{
        font-size: medium
    }

    .homeIntro p{
        font-size: small;
        width: 85%;
    }

    .introButtons{
        margin-top: 10%;
    }
    .homeImage{
        /* background-color: #e8e8e8; */
        margin-top: 10%;
        width: 50%;
    }
    .homeImage img{
        /* margin-top: 20%; */
        width: 50%;
    }

    .corner-box {
        position: absolute;
        width: 65%;
        height: 60%;
        border-color: black;    
        border-radius: 8px;
        /* margin-top: 4%; */
        pointer-events: none;
    }
    
    .linkDiv{
        z-index: 1;
    }
    .linkedIn{
     
        width: 20px;
        
    }

    .gitHub{
        width: 20px;
    }

    .emailIcon{
        width: 20px;
    }
}