.nav{

    width: 100%;
    display: flex;
   
    justify-content: space-between;
}

.nav-logo{
  flex: 1;
  display: flex;
}

.nav-logo img{
    height: 70px;
    width: 70px;
    transition: transform 0.3s;
}

.nav-logo img:hover{
    cursor: pointer;
    transform: scale(1.3);
}

.nav-links{
    flex: 1;
}

.nav-links ul{
    display: flex;
    justify-content: space-evenly;
    list-style: none;
}

.nav-links ul li{
    font-weight: 500;
    z-index: 1;
    /* transition: transform 0.3s; */
    transition: background-color 0.3s ease;
}

.nav-links ul li:hover{
    /* font-weight: 600; */
    cursor: pointer;
    transform: scale(1.12);

}

.nav-item {
    /* Your existing styles for nav items */
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
  .nav-item.active {
    /* Styles for the active page */
    font-weight: 700;
    color: #000000; /* Or any other color that fits your design */
    border-bottom: 2px solid #ffa500; /* Example style */}
  

.nav-links hr{
    width: 81%;
    margin-right: 1%;
    border: 1px solid grey;
}

@media (max-width: 670px) {
    .nav-links{
        margin-right: 2%;
    }
    .nav-links ul{
        gap: 0.8rem;
    }
    .nav-links ul li{
        font-size: small;
        /* margin-right: 8%; */
    }
}